import Vue from 'vue'
import axios from 'axios'
import lodash from 'lodash'
import store from './storage.js'
import App from '../app.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'flexbox-set/src/index.css'
import './application.scss'
import ActionCableVue from 'actioncable-vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

Vue.use(BootstrapVue)
Vue.use(ActionCableVue, {
    debug: true,
    debugLevel: 'error',
    connectionUrl: window.gon.region.action_cable_url,
    connectImmediately: true,
})

const requireComponent = require.context(
    '../components/descriptions',
    false
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)

    const componentName = upperFirst(
        camelCase(
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
    )
    Vue.component(
        componentName,
        componentConfig.default || componentConfig
    )
})

Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });
Object.defineProperty(Vue.prototype, '$http', { value: axios });

document.addEventListener('DOMContentLoaded', () => {
    let meta = document.querySelector("meta[name='csrf-token']");
    meta ? Vue.prototype.$http.defaults.headers.common['X-CSRF-TOKEN'] = meta.getAttribute('content') : false;
    new Vue({
        el: '#app',
        store,
        render: h => h(App)
    })
});