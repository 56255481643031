<template>
    <div id="siteInfo-block">
        <b-button v-b-modal.modal-siteinfo id="siteinfo_btn" variant="warning">?</b-button>
        <b-modal id="modal-siteinfo" no-close-on-esc no-close-on-backdrop hide-header hide-footer>
            <b-button @click="$bvModal.hide('modal-siteinfo')">×</b-button>
            <p style="font-size: 2em">Сайт — <a :href="siteHref" target="blank">{{siteName}}</a></p>
            <component :is="description" />
            <div v-html="siteThanks" />
        </b-modal>
    </div>
</template>
<script>
import camelCase from 'lodash/camelCase'

export default {
    computed: {
        siteThanks() {
            return this.$store.state.gon.display_site_thanks
        },
        siteName() {
            return this.$store.state.gon.display_site_name
        },
        siteHref() {
            return this.$store.state.gon.display_site_href
        },
        description() {
            return camelCase(`${this.$store.state.gon.description || 'default'}_description`)
        }
    }
}
</script>
<style lang="scss">
#modal-siteinfo___BV_modal_outer_ {

    .modal-dialog {
        max-width: 99%;
        height: 100%;
        margin: auto;
    }

    #modal-siteinfo___BV_modal_body_ {
        button {
            float: right;
            font-weight: bold;

        }
    }
}

#siteInfo-block {
    float: right;

    #siteinfo_btn {
        color: #fff;
    }
}
</style>